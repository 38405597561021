/* Colors */

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-black-shadow: rgba(0, 0, 0, 0.4);
  --color-blue-dark-1: hsl(212, 52%, 34%);
  --color-blue-dark-2: hsl(214, 49%, 21%);
  --color-bright-green: hsl(84, 62%, 55%);
  --color-red: hsl(0, 69%, 43%);

  --color-gray-light-1: hsl(0, 9%, 98%);
  --color-gray-light-2: hsl(0, 8%, 95%);
  --color-gray-light-3: hsl(0, 6%, 94%);
  --color-gray-light-4: hsl(0, 0%, 84%);
  --color-gray-light-5: hsl(0, 0%, 67%);
  --color-gray-light-6: hsl(0, 0%, 60%);
  --color-gray-light-7: hsl(0, 0%, 40%);
  --color-gray-light-8: hsl(0, 0%, 27%);

  --page-width: 124rem;
  --page-padding: 0 min(6vw, 3rem);
  --header-height: 7.2rem;
  --hover-transition-speed: 0.3s;
  --small-border-radius: 0.5rem;
  --large-border-radius: 1rem;
  --contact-form-width: 74rem;

  --color-main-text: var(--color-blue-dark-2);
  --color-bg-lighter: var(--color-blue-dark-1);
  --color-bg-darker: var(--color-blue-dark-2);
  --color-highlight: var(--color-bright-green);
}

/* Reset */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  color: var(--color-main-text);
}

html,
button,
input,
textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  font-family: 'Archivo', sans-serif;
}

body {
  display: flex;
  justify-content: center;
  background: radial-gradient(ellipse at center 20%, var(--color-bg-lighter), var(--color-bg-darker));
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

ul {
  list-style-type: none;
}
button {
  border: 0;
  cursor: pointer;
  border-radius: var(--small-border-radius);
}

/* Basic typography styles */

a {
  color: inherit;
  text-decoration: none;
}
.bold {
  font-weight: 700;
}

.header a:hover,
.header a:hover p,
.footer a:hover {
  color: var(--color-highlight);
  transition: color 0.2s ease-out;
}
.header a:hover svg,
.header a:hover svg ellipse,
.footer a:hover svg {
  fill: var(--color-highlight);
  transition: fill 0.2s ease-out;
}

.page-headline {
  margin-bottom: 6rem;
}
.page-headline h1 {
  font-size: 3.9rem;
  font-size: clamp(3.6rem, 3.48rem + 0.6vw, 4.2rem);
  margin-bottom: 0.5rem;
}
.page-headline h2 {
  font-size: 2.4rem;
  font-size: clamp(2.2rem, 2.14rem + 0.3vw, 2.5rem);
  margin-bottom: 0.5rem;
}

/* Overlay styles */

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-black-shadow);
  transition: all var(--hover-transition-speed) ease-in-out;
}

/* Section containers */

.header,
.main,
.footer {
  width: 100%;
}

.container {
  max-width: var(--page-width);
  margin: 0 auto;
  padding: var(--page-padding);
}
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-center-align {
  text-align: center;
}

/* Header styles */

.header {
  color: var(--color-white);
  height: var(--header-height);
  padding-top: calc(var(--header-height) / 4);
}
.header p,
.header a {
  color: var(--color-white);
}
.header svg {
  fill: var(--color-white);
}
.header-logo p {
  font-size: 2.1rem;
  font-size: clamp(1.9rem, 1.84rem + 0.3vw, 2.2rem);
  font-weight: 300;
}
.header-logo p span {
  font-weight: 400;
}

/* Navigation styles */

.nav-desktop {
  display: none;
}
@media screen and (min-width: 576px) {
  .nav-desktop {
    display: block;
  }
}
.nav-desktop ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  gap: min(3vw, 3.2rem);
  font-size: 1.6rem;
}
.nav-desktop ul li {
  position: relative;
  padding: 0.3rem 0 0.7rem 0;
  /* height: 3rem; */
}
.nav-desktop--svg a {
  font-size: 0;
}
.nav-desktop a::after {
  visibility: hidden;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.2rem;
  background-color: var(--color-white);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-duration: 0.2s;
}
.nav-desktop a:hover,
.nav-desktop a.active {
  color: inherit;
}
.nav-desktop a:hover::after,
.nav-desktop a.active::after {
  visibility: visible;
  transform: scaleX(1);
}
.nav-desktop svg {
  width: 2rem;
}
.nav-desktop a:hover svg {
  fill: var(--color-white);
}
.nav-desktop svg.svg-resume {
  width: 2.8rem;
}

/* Nav burger styles */

@media screen and (min-width: 576px) {
  .nav-burger,
  .mobile-nav {
    display: none;
  }
}
.nav-burger {
  cursor: pointer;
  border: 0;
  height: 2.6rem;
  width: 3.2rem;
  position: relative;
  background-color: inherit;
}
.nav-burger .nav-burger--line,
.nav-burger .nav-burger--line::before,
.nav-burger .nav-burger--line::after {
  position: absolute;
  top: 1.1rem;
  left: 0;
  height: 0.4rem;
  width: 3.2rem;
  border-radius: var(--small-border-radius);
  background: var(--color-white);
  transition: all var(--hover-transition-speed) ease-in-out;
}
.nav-burger .nav-burger--line::before {
  content: '';
  top: -1rem;
}
.nav-burger .nav-burger--line::after {
  content: '';
  top: 1rem;
}
.nav-burger[aria-expanded='true'] .nav-burger--line {
  background: none;
}
.nav-burger[aria-expanded='true'] .nav-burger--line::before {
  top: 0;
  transform: rotate(45deg);
}
.nav-burger[aria-expanded='true'] .nav-burger--line::after {
  top: 0;
  transform: rotate(-45deg);
}

/* Mobile nav styles */

.mobile-nav {
  position: absolute;
  z-index: 2;
  left: 0;
  top: var(--header-height);
  width: 100%;
  text-align: center;
  padding: 2.2rem 0;
  transition: 0.3s;
  transform: scaleY(0);
  transform-origin: top;
  background: linear-gradient(to right bottom, var(--color-gray-light-1), var(--color-gray-light-3));
}
.mobile-nav a,
.mobile-nav a:visited {
  color: var(--color-main-text);
}
.nav-burger[aria-expanded='true'] + .mobile-nav {
  transform: scaleY(1);
}
.mobile-nav ul {
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
}
.mobile-nav .mobile-nav__link {
  font-size: 2.2rem;
}
.mobile-nav .mobile-nav__link svg {
  width: 2rem;
  fill: var(--color-main-text);
}
.mobile-nav .mobile-nav__link--resume svg {
  width: 3rem;
}

.mobile-nav__overlay {
  opacity: 0;
  visibility: hidden;
  top: var(--header-height);
}
.nav-burger[aria-expanded='true'] ~ .mobile-nav__overlay {
  opacity: 1;
  visibility: visible;
}

/* Main styles */

.main {
  flex: 1;
  text-align: center;
  padding: 6rem 0;
  background: radial-gradient(ellipse at center 20%, var(--color-white), var(--color-gray-light-3));
  box-shadow: inset 0 0 1rem rgb(0 0 0 / 50%);
}

/* Home top styles */

.home-top {
  margin-bottom: 5.5rem;
}

.home-headline,
.home-intro {
  margin: 0 auto;
}
@media screen and (min-width: 576px) {
  .home-headline,
  .home-intro {
    width: 75%;
  }
}
.home-headline {
  margin-bottom: 4rem;
}
.home-headline h1 {
  font-size: 2.5rem;
  margin-bottom: 0.4rem;
  text-transform: uppercase;
}
.home-headline h2 {
  font-size: 3.9rem;
  font-size: clamp(3.6rem, 3.48rem + 0.6vw, 4.2rem);
  margin-bottom: 0.5rem;
}
.home-headline p {
  font-size: 1.9rem;
  font-size: clamp(1.8rem, 1.76rem + 0.2vw, 2rem);
  line-height: 2.6rem;
  font-weight: 400;
}
.home-intro p {
  font-size: 1.6rem;
  font-size: clamp(1.5rem, 1.46rem + 0.19999999999999996vw, 1.7rem);
  line-height: 2.8rem;
  text-align: left;
}
.home-intro p:not(:last-of-type) {
  margin-bottom: 2.5rem;
}

/* Home bottom styles */

.portfolio-cards-headline {
  margin-bottom: 4.5rem;
}
.portfolio-cards-headline h3 {
  font-size: 3.4rem;
  font-size: clamp(3.4rem, 3.24rem + 0.8vw, 4.2rem);
}
.portfolio-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  gap: 5rem;
}
.portfolio-cards .card {
  text-align: left;
  border-radius: var(--large-border-radius);
  box-shadow: 0.2rem 0.2rem 1rem var(--color-black-shadow);
  overflow: hidden;
  /* background-clip: border-box; */
}
.portfolio-cards .card img {
  width: 100%;
}
.portfolio-cards .card .card-body {
  padding: 1.8rem;
}
.portfolio-cards .card .card-body h4 {
  font-size: 2.5rem;
  line-height: 2.9rem;
  margin-bottom: 1rem;
}
.portfolio-cards .card .card-body p {
  font-size: 1.4rem;
  line-height: 1.9rem;
}

/* Portfolio page styles */

.portfolio {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  margin: 0 min(2vw, 4rem);
  text-align: left;
  font-size: 1.8rem;
  line-height: 2.5rem;
}
@media screen and (min-width: 900px) {
  .portfolio {
    flex-direction: row;
  }
}
.portfolio > div {
  flex: 1;
}
.portfolio img {
  width: 100%;
  border-radius: var(--large-border-radius);
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.5);
}
.portfolio-description {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.portfolio-description a {
  font-size: 2.1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.portfolio-description svg {
  width: 1.9rem;
  fill: var(--color-main-text);
}
.portfolio-description a:hover,
.portfolio-description a:hover svg {
  color: var(--color-highlight);
  fill: var(--color-highlight);
  transition: all 0.2s ease-out;
}
.portfolio-description ul {
  list-style-type: disc;
  margin-left: 3rem;
}
.portfolio-description ul li {
  margin-bottom: 1.5rem;
}

/* Contact page styles */

.contact {
  max-width: var(--contact-form-width);
  margin: 0 auto;
  text-align: left;
}
.contact .contact-intro {
  margin-bottom: 4rem;
}
.contact .contact-intro p {
  font-size: 1.8rem;
  line-height: 2.5rem;
}

.contact-form {
  padding: 2.2rem;
  margin-bottom: 3rem;
  border-radius: var(--large-border-radius);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: linear-gradient(to right bottom, var(--color-gray-light-3), var(--color-gray-light-4));
}
.contact-form p,
.contact-form label {
  font-size: 1.8rem;
}
.contact-form p.form-error-message {
  color: var(--color-red);
}
.contact-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  font-size: 1.6rem;
  border: 2px solid var(--color-blue-dark-1);
  border-radius: var(--small-border-radius);
  padding: 1.2rem 1.5rem;
  margin-bottom: 0.8rem;
  outline-offset: 4px;
}
.contact-form .form-error {
  border-color: var(--color-red);
}
.contact-form input,
.contact-form textarea,
.contact-form button {
  box-shadow: 0.3rem 0.3rem 1rem var(--color-black-shadow);
}
.contact-form button {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-white);
  background-color: var(--color-blue-dark-1);
  padding: 1.2rem 5rem;
  align-self: flex-start;
  transition: all var(--hover-transition-speed) ease-in-out;
}
.contact-form button:hover {
  color: var(--color-blue-dark-1);
  background-color: var(--color-highlight);
}

/* Footer styles */

.footer {
  max-width: 74rem;
  padding: 3.4rem 0 4rem 0;
  text-align: center;
}
.footer p,
.footer a {
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: var(--color-white);
}
.footer .footer__blurb {
  margin: 0 auto 4rem auto;
}
.footer .footer__bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer .footer__links,
.footer .footer__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
}
.footer .footer__icons svg {
  width: 2rem;
  fill: var(--color-white);
}
.footer .footer__icons .footer__icons--resume {
  width: 3rem;
}
